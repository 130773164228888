import React from 'react'
import Layout from '../../components/Demo/Layout'
import Coffee from '../../components/2021/Coffee/Coffee'

const CoffeePage = () => {
  const pageSlug = 'coffee'

  return (
    <Layout pageSlug={pageSlug}>
      <Coffee />
    </Layout>
  )
}

export default CoffeePage
